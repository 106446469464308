export function AgeFormat(value) {
  if (typeof value !== 'undefined') {
    const splitData = value.split('-')
    return `${splitData[0]}年${splitData[1]}月${splitData[2]}日`
  }
  return ''
}

export function getAge(birthday) {
  // 今日
  const today = new Date()

  // 今年の誕生日
  const thisYearsBirthday = new Date(today.getFullYear(), birthday.month - 1, birthday.date)

  // 年齢
  let age = today.getFullYear() - birthday.year

  if (today < thisYearsBirthday) {
    // 今年まだ誕生日が来ていない
    age -= 1
  }
  return age
}

export function showAge(value) {
  if (typeof value !== 'undefined') {
    const splitData = value.split('-')
    const birthday = {
      year: splitData[0],
      month: splitData[1],
      date: splitData[2],
    }
    return getAge(birthday)
  }
  return ''
}
